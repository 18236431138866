import authConfig from "@/configs/auth";
import { getApiKeyHeaders } from "@/libs/utils";
import { ApiKeyHeaders } from "@/@types/util";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { createWithEqualityFn } from "zustand/traditional";

type State = {
  loading: false | true;
  gameUrl: string;
  errMessage?: string;
  dialogOpen: boolean;
  isIframe?: boolean;
};

type Actions = {
  launchGame: (props: {
    gameId: string;
    bonusMode: string;
    isMobile: boolean;
    isIframe?: boolean;
    onSuccess: (gameUrl: string) => void;
    onError: (message: string) => void;
  }) => Promise<void>;
  setGameUrl: (url: string) => void;
  setDialogOpen: (open: boolean) => void;
  setErrMessage?: (errMessage: string) => void;
  setIsIframe: (isIframe: boolean) => void;
};

export const useLaunchStore = createWithEqualityFn(
  devtools(
    immer<State & Actions>((set) => ({
      gameUrl: "",
      dialogOpen: false,
      isIframe: true,
      loading: false,
      setGameUrl: (url) => {
        set((state) => ({ gameUrl: url }));
      },
      setIsIframe: (isIframe) => {
        set((state) => ({ isIframe }));
      },
      setDialogOpen: (open) => {
        set((state) => ({ open }));
      },
      launchGame: async ({
        gameId,
        bonusMode,
        isMobile,
        isIframe,
        onSuccess,
        onError,
      }) => {
        set((_) => ({ loading: true, isIframe }));
        try {
          const accessToken = window.localStorage.getItem(
            authConfig.storageTokenKeyName
          );
          if (accessToken) {
            const xApiKeyHeaders: ApiKeyHeaders = getApiKeyHeaders();
            const response = await fetch(
              `${process.env.NEXT_PUBLIC_APP_API_LAUNCH_GAME}/api/v2/members/games/launchGame?game_id=${gameId}&m=${isMobile}&bonus_mode=${bonusMode}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  agent: xApiKeyHeaders.agent,
                  time: xApiKeyHeaders.time.toString(),
                  "X-Api-Key": xApiKeyHeaders["X-Api-Key"],
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            );
            const data = await response.json();
            if (data.data) {
              const blackList = ['pg1play', 'the1laos', 'hengmak168', 'dragonheng168', 'deemark168']
              const hasBlackList = blackList.find((agent: any) => agent === xApiKeyHeaders.agent)
              const result = data.data;

              if (parseInt(gameId, 10) >= 8100 && parseInt(gameId, 10) <= 8279) {
                const url = result.url.replace("http://", 'https://')

                if (hasBlackList) {
                  set((_) => ({
                    // gameUrl: url,
                    loading: false,
                  }));
                  window.location.assign(url)
                  onSuccess(url);
                  return result;
                }

                set((_) => ({
                  gameUrl: url,
                  loading: false,
                }));
                onSuccess(url);
                return result;
              }

              if (hasBlackList) {
                set((_) => ({
                  // gameUrl: url,
                  loading: false,
                }));
                window.location.assign(result.url)
                onSuccess(result.url);
                return result;
              }

              set((_) => ({
                gameUrl: result.url,
                loading: false,
              }));
              onSuccess(result.url);
              return result;
            } else {
              set((_) => ({ errMessage: data.message, loading: false }));
              onError(data.msg);
              return { errMessage: data.msg };
            }
          }
        } catch (error) {
          console.log("launchGame method in launchStore error:", error);
        } finally {
          set((state) => ({ loading: false }));
        }
      },
    }))
  ),
  Object.is
);
